import React from "react";

const Promo = () => {
  return (
    <>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <h2>Art of Modern Finance</h2>
              </div>
            </div>
          </div>
          <div className="row equal">
            <div className="col-md-4 col-lg-3">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-vector text-white"></span>
                </div>
                <h5>Multiple devices support</h5>
                <p>
                  Take control of a wide range of devices, including
                  smartphones, tablets, laptops, and digital signage, running on
                  different operating systems but managing in one platform.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-lock text-white"></span>
                </div>
                <h5>Certified Android Enterprise Partner</h5>
                <p>
                  As a certified Android EMM Provider and Reseller, we provide
                  turn-key solutions for manufacturers and OEMs, adhering to the
                  highest industry standards.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-eye text-white"></span>
                </div>
                <h5>Ease of use, powerful in function</h5>
                <p>
                  We combine cutting-edge technology with a user-friendly
                  interface, providing the most powerful device management
                  platform on the market without sacrificing ease of use.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-eye text-white"></span>
                </div>
                <h5>24/7 Support</h5>
                <p>
                  Don't hesitate to leave your questions here and we promise you
                  we will read your messages manually. The more information you
                  provide, the faster we can give you the professional guidance
                  you require.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
