import React from "react";

export default function Policy({ Title, space }) {
  return (
    <>
      <section className={`gray-light-bg  ${space ? "ptb-100" : "pb-60"}`}>
        <div className="container">
          {Title ? (
            <div className="row">
              <div className="">
                <div className="section-heading mb-4">
                  <h2>Policy summary</h2>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <p>
              This Privacy Policy (“Policy”) describes the manner in which
              EMIBook website at www.EMIBook.in (the “Site”), and our mobile
              Application, as well as all related websites, networks,
              applications, and other services provided by us and on which a
              link to this Policy is displayed (collectively, together with the
              Site, and the mobile Application our “Service”), gather
              information from you on the Service, how we use and disclose such
              information, and the steps we take to protect such information.
            </p>
          </div>
          <div className="row">
            <div className="mb-1">
              <h3>Information we Collect</h3>
            </div>
          </div>
          <div className="row">
            <div>
              <div className="section-heading mb-4">
                <h2>User-provided Information</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <p>
              When you use the Service, we may collect information about you,
              including your name, first name and last name, email address,
              mobile phone number, phone number, geographic area, or
              preferences, and we may link this information with other
              information about you. You may provide us with information in
              various ways on the Service. For example, you provide us with
              information when you register for an account, use the service,
              send us customer service-related requests, or through cookies.
            </p>
          </div>
          <div className="row">
            <div>
              <div className="section-heading mb-4 text-center">
                <h2>Cookies and Automatically Collected Information</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <p>
              When you use the Service, we may send one or more cookies – small
              text files containing a string of alphanumeric characters – to
              your device. We may use session cookies, preference cookies and
              security cookies. Please note that if you delete, or choose not to
              accept, cookies from the Service, you may not be able to utilize
              the features of the Service to their fullest potential.
            </p>
            <p>
              Similarly, we collect the information of crash reporting,
              application logging, online review and statistical analysis of
              application logs.
            </p>
            <p>
              We may also automatically record certain information from your
              device by using various types of technology. This automatically
              collected information may include your IP address or other device
              address or ID, web browser and/or device type, the web pages or
              sites that you visit just before or just after you use the
              Service, the pages or other content you view or otherwise interact
              with on the Service, and the dates and times that you visit,
              access, or use the Service. We also may use these technologies to
              collect information regarding your interaction with email
              messages, such as whether you opened, clicked on, or forwarded a
              message. This information is gathered from all users and may be
              connected with other information about you.
            </p>
          </div>
          <div className="row">
            <div>
              <div className="section-heading mb-4">
                <h2>Log files</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <p>
              EMIBook follows a standard procedure of using log files. These
              files log visitors when they visit websites. All hosting companies
              do this and a part of hosting services analytics. The information
              collected by log files include internet protocol (IP) addresses,
              browser type, Internet Service Provider (ISP), date and time
              stamp, referring/exit pages, and possibly the number of clicks.
              These are not linked to any information that is personally
              identifiable. The purpose of the information is for analysing
              trends, administering the site, tracking users’ movement on the
              website, and gathering demographic information.
            </p>
          </div>
          <div className="row">
            <div>
              <div className="section-heading mb-4">
                <h2>How We Use the Information We Collect</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <p>
              We use information we collect on / through the Service in a
              variety of ways in providing the Service and operating our
              business, including the following: <br /> <br /> 1.We use the
              information that we collect on the Service to operate, maintain,
              enhance and provide all features of the Service, to provide
              services and information that you request, and to respond to
              comments and questions and otherwise to provide support to users.{" "}
              <br /> <br />
              2.We use the information that we collect on the Service to
              understand and analyse the usage trends and preferences of our
              users, to improve the Service, and to develop new products,
              services, features, and functionality. <br /> <br /> 3.We may use
              your email address or other information we collect to contact you
              for administrative purposes such as customer service or to send
              communications, including updates relating to products and
              services offered by us and by third parties and you hereby consent
              to receiving such communications by agreeing to these terms.{" "}
              <br /> <br /> 4.We may use cookies and automatically collected
              information to: (i) personalize our Service, such as remembering
              information about you so that you will not have to re-enter it
              during your visit or the next time you visit the Service; (ii)
              provide customized advertisements, content, and information; (iii)
              monitor and analyse the effectiveness of the Service activities;
              (iv) monitor aggregate site usage metrics such as total number of
              users, features used, and pages viewed; and (v) track your
              interactions, submissions, status or other activities on the
              Service.
            </p>
          </div>
          <div className="row">
            <div>
              <div className="section-heading mb-4">
                <h2>Disclosure of Information</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <p>
              Except as described in this Policy, we will not disclose
              information about you that we collect on the Service to
              third-parties without your consent. We may disclose information to
              third parties if you consent to us doing so, as well as in the
              following circumstances: <br />
              <br /> 1.Any information that you voluntarily choose to include in
              a publicly accessible area of the Service will be available to
              anyone who has access to that content, including other users.{" "}
              <br />
              <br /> 2.We may work with third-party service providers to provide
              various services. These third-party service providers may have
              access to or process information about you as part of providing
              those services for us. Generally, we limit the information
              provided to these service providers to that which is reasonably
              necessary for them to perform their functions, and we require them
              to agree to maintain the confidentiality of such information.{" "}
              <br />
              <br /> 3.We may disclose information about you if required to do
              so by law or in the good-faith belief that such action is
              necessary to comply with state and federal/central laws, in
              response to a court order, judicial or other government subpoena
              or warrant, or to otherwise cooperate with law enforcement or
              other governmental agencies. <br />
              <br /> 4.We also reserve the right to disclose information about
              you that we believe, in good faith, is appropriate or necessary to
              (i) take precautions against liability, (ii) protect ourselves or
              others from fraudulent, abusive, or unlawful uses or activity,
              (iii) investigate and defend ourselves against any third-party
              claims or allegations, (iv) protect the security or integrity of
              the Service and any facilities or equipment used to make the
              Service available, or (v) protect our property or other legal
              rights (including, but not limited to, enforcement of our
              agreements), or the rights, property, or safety of others. <br />
              <br /> 5.Information about our users may be disclosed and
              otherwise transferred to an acquirer, successor, or assignee as
              part of any merger, acquisition, debt financing, sale of assets,
              or similar transaction, or in the event of any insolvency,
              bankruptcy, or receivership in which information is transferred to
              one or more third parties as one of our business assets.
            </p>
          </div>
          <div className="row">
            <div>
              <div className="section-heading mb-4">
                <h2>Children's Privacy</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <p>
              Protecting the privacy of young children is important. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 18. If you are a parent or guardian and you are
              aware that your Children has provided us with Personal Data,
              please contact us. If we become aware that we have collected
              Personal Data from children without verification of parental
              consent, we take steps to remove that information from our
              servers.
            </p>
          </div>
          <div className="row">
            <div>
              <div className="section-heading mb-4">
                <h2>Data Security</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <p>
              We use certain physical, managerial, and technical safeguards that
              are designed to improve the integrity and security of information
              that we collect and maintain as required by applicable law. Please
              be aware that no security measures are perfect or impenetrable. We
              cannot and do not guarantee that information about you will not be
              accessed, viewed, disclosed, altered, or destroyed by breach of
              any of our physical, technical, or managerial safeguards.
            </p>
          </div>
          <div className="row">
            <div>
              <div className="section-heading mb-4">
                <h2>Data Transfer</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <p>
              Your information, including Personal Data, may be transferred to
              and maintained on computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ than those from your jurisdiction
              operating the Service. By providing any information, including
              personal information, on or to the Service, you consent to such
              transfer, storage, and processing. <br />
              <br /> If you are located outside United States and choose to
              provide information to us, please note that we may transfer the
              data, including Personal Data, to United States and process it
              there. Your consent to this Privacy Policy followed by your
              submission of such information represents your agreement to that
              transfer.
            </p>
          </div>
          <div className="row">
            <div>
              <div className="section-heading mb-4">
                <h2>Changes and Updates to this Policy</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <p>
              Please revisit this page periodically to stay aware of any changes
              to this Policy, which we may update from time to time. In the
              event that the modifications materially alter your rights or
              obligations hereunder, we will use reasonable efforts to notify
              you of the change. Your continued use of the Service after the
              revised Policy has become effective indicates that you have read,
              understood and agreed to the current version of this Policy.
            </p>
          </div>
          <div className="row">
            <div>
              <div className="section-heading mb-4">
                <h2>How to Contact Us</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <p>
              Please contact us with any questions or comments about this
              Policy, information we have collected or otherwise obtained about
              you, our use and disclosure practices, or your consent choices by
              email at <a href="mailto: hello@EMIBook.in">hello@EMIBook.in</a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
