import React from "react";
// import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import Policy from "../components/others/Policy";
import DeleteAccount from "../components/others/DeleteAccount";

export default function PrivacyPolicy() {
  return (
    <Layout>
      {/* <Navbar darkBg /> */}
      <PageHeader HeaderTitle="EMI Book Privacy Policy" />
      {/* <Accordion Title /> */}
      <Policy Title space />
      <DeleteAccount Title space />
      {/* <Footer /> */}
    </Layout>
  );
}
